(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("react-dom"));
	else if(typeof define === 'function' && define.amd)
		define([, ], factory);
	else if(typeof exports === 'object')
		exports["\"@safe-global/safe-react-components\""] = factory(require("react"), require("react-dom"));
	else
		root["\"@safe-global/safe-react-components\""] = factory(root[undefined], root[undefined]);
})(this, function(__WEBPACK_EXTERNAL_MODULE__225__, __WEBPACK_EXTERNAL_MODULE__779__) {
return 